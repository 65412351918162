<template>
  <div class="container">
    <h4 class="font-weight-bold text-center">Cerificado de compra</h4>
    <!-- <h6 class="text-center mt-5">Informacion del asegurado</h6 -->

    <!-- <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Email</th>
            <th scope="col">Contratante</th>
            <th scope="col">Telefono</th>
            <th scope="col">Direccion</th>
            <th scope="col">Pais</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ASsSSsaSAs</td>
            <td>ASsSSsaSAs</td>
            <td>ASsSSsaSAs</td>
            <td>ASsSSsaSAs</td>
            
          </tr>
          
        </tbody>
      </table>
    </div> -->

    <!-- <b-button @click="getQuery()">click!</b-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: ["Nombre", "apellido", "email", "contratante"],
    };
  },

  methods: {
    // getQuery() {
    //   console.log(this.query);
    // },
  },

  computed: {
    // query() {
    //   return this.$route.query;
    // },
  },
};
</script>

<style>
.rowing {
  border: 1px solid black;
}
</style>